import { useEffect, useState } from 'react';

const useGetCountryCode = () => {
  const [countryCode, setCountryCode] = useState<string | undefined>(undefined);

  const fetchCountryCode = async () => {
    try {
      const response = await fetch('https://ipinfo.io/json', {
        method: 'GET',
      });
      if (!response.ok) {
        setCountryCode('VN');
      }
      const data = (await response.json()) as { country: string };

      if (data?.country) {
        setCountryCode(data.country);
      }
    } catch {
      setCountryCode('VN');
    }
  };
  useEffect(() => {
    void fetchCountryCode();
  }, []);
  return countryCode;
};
export default useGetCountryCode;
