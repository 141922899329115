import { ExamDomainQuery } from '@/__generated__/graphql';
import { useEffect, useMemo } from 'react';
import { BgImage } from '@flyer/components/next';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useAuth } from '@/providers/auth';
import bingoVn from './bingo-vn.png';
import bingoEn from './bingo-en.png';
import background from './bg.png';

/** Layout for guest: login, register, forgot password */
export default function AuthLayout({
  children,
  dataDomain,
}: {
  children: React.ReactNode;
  dataDomain?: ExamDomainQuery;
}) {
  const { lang } = useTranslation();
  const { user } = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (user && typeof router.query.auth_redirect_url !== 'string') {
      // keep query when push
      void router.push({
        pathname: '/',
        query: { ...router.query },
      });
    }
  }, [router, user]);

  const renderBackground = useMemo(() => {
    if (dataDomain?.partnerDomain?.meta?.examBackgroundImage?.url) {
      return dataDomain?.partnerDomain?.meta?.examBackgroundImage?.url;
    }
    return background;
  }, [dataDomain?.partnerDomain?.meta?.examBackgroundImage?.url]);

  const renderLogo = useMemo(() => {
    if (dataDomain?.partnerDomain?.meta?.examLogoImage?.url) {
      return dataDomain?.partnerDomain?.meta?.examLogoImage?.url;
    }
    return lang === 'en' ? bingoEn : bingoVn;
  }, [dataDomain?.partnerDomain?.meta?.examLogoImage?.url, lang]);

  return (
    <BgImage
      alt="Flyer testing platform"
      src={renderBackground}
      imageClassName="w-full h-full object-cover"
      wrapClassName="fixed inset-0 w-full h-full"
      className="overflow-x-hidden overflow-y-auto flex flex-col items-center justify-center"
    >
      <div className="flex flex-col lg:flex-row gap-5 items-center justify-center lg:w-[60rem] m-auto xl:w-[67.5rem] 2xl:w-[93.75rem] w-full p-2">
        <div className="lg:flex-1">
          <Image
            src={renderLogo}
            alt="Flyer"
            width={1500}
            height={1500}
            quality={100}
            className="max-w-full m-auto w-[60%]"
          />
        </div>
        <div className="flex flex-col gap-8 text-center sm:w-[70%] lg:w-[50%] xl:w-[45%] 2xl:w-[35%] w-[95%]">
          {children}
        </div>
      </div>
    </BgImage>
  );
}
