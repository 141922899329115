import { PhoneInputCountryCodesQueryQuery } from '@/__generated__/graphql';
import useGetCountryCode from '@/hooks/useGetCountryCode';
import { useAuth } from '@/providers/auth';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useQuery } from '@apollo/client';
import {
  ModalInput as Input,
  ModalDropdown as Dropdown,
} from '@/components/common/Modal/components';
import { graphql } from '@/__generated__';
import { twMerge } from 'tailwind-merge';

const PhoneInputCountryCodesQuery = graphql(/* GraphQL */ `
  query PhoneInputCountryCodesQuery {
    countryCodes {
      dialCode
      isoCode
      countryCodeId
    }
  }
`);

const DEFAULT_COUNTRY_CODE = '+84';

interface PhoneInputProps {
  name: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  error?: React.ReactNode;
  isDefaultShowCountryCode?: boolean;
}

export default function PhoneInput(props: PhoneInputProps) {
  const { name, onChange, onBlur, error, isDefaultShowCountryCode = true } = props;
  const { t } = useTranslation('auth');
  const { isFlyerUs } = useAuth();
  const currentUserCountryCode = useGetCountryCode();
  const [countryCode = DEFAULT_COUNTRY_CODE, setCountryCode] = useState<string | undefined>();

  const { data } = useQuery<PhoneInputCountryCodesQueryQuery>(PhoneInputCountryCodesQuery, {
    context: { v2: true },
  });

  const countryCodes = useMemo(() => {
    if (data) {
      return [...new Set(data.countryCodes.map((code) => code.dialCode))].map((code) => ({
        title: code,
        value: code,
      }));
    }
    return [{ title: DEFAULT_COUNTRY_CODE, value: DEFAULT_COUNTRY_CODE }];
  }, [data]);

  useEffect(() => {
    if (currentUserCountryCode && data?.countryCodes) {
      const dataCountryCode = data?.countryCodes?.find((i) => i.isoCode === currentUserCountryCode);
      if (dataCountryCode?.dialCode) {
        setCountryCode(dataCountryCode?.dialCode);
      }
    }
  }, [currentUserCountryCode, data?.countryCodes]);

  const [isShowCountryCode, setShowCountryCode] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      // remove spaces
      let v = e.target.value.replaceAll(/\s/g, '');

      const containChar = /[A-Z_a-z]/.test(e.target.value || '');

      // is number
      if (v.charAt(0) >= '0' && v.charAt(0) <= '9') {
        setShowCountryCode(true);
      } else {
        setShowCountryCode(false);
      }
      // remove country code
      if (v.startsWith(countryCode)) {
        v = v.slice(countryCode.length);
      }

      // remove number start with 0 and non-number characters if country code is +84
      if (countryCode === '+84' && !containChar) {
        v = v.replace(/^0/, '').replaceAll(/\D/g, '');
      }

      // apply onChange
      e.target.value = v;
      if (v) {
        onChange((containChar ? '' : countryCode) + e.target.value);
      } else {
        onChange('');
      }
    },
    [countryCode, onChange],
  );

  const onSelectCountryCode = useCallback(
    (v?: string) => {
      if (!v || !inputRef.current) {
        return;
      }

      setCountryCode(v);
      onChange((v ?? DEFAULT_COUNTRY_CODE) + inputRef.current.value);
    },
    [onChange],
  );

  return (
    <div className="relative">
      {(isShowCountryCode || isDefaultShowCountryCode) && (
        <Dropdown
          wrapClassName="absolute z-[12] left-0 text-center text-white w-auto md:min-w-[130px]"
          isRelative={false}
          items={countryCodes}
          selected={countryCode}
          inputProps={{
            size: 5,
            bold: true,
          }}
          onSelect={onSelectCountryCode}
          hasSearchable
        />
      )}
      <Input
        ref={inputRef}
        error={error}
        name={name}
        onChange={onInputChange}
        onBlur={onBlur}
        placeholder={
          isDefaultShowCountryCode
            ? t('form.phone_number.placeholder')
            : t('form.phone_number.placeholder_phone_2')
        }
        className={twMerge(
          'text-white placeholder:text-[#7BFAFC]',
          isShowCountryCode || isDefaultShowCountryCode
            ? 'pl-[6rem] sm:pl-[7rem] xl:pl-[8.5rem]'
            : '',
        )}
        errorClassName="text-center"
      />
    </div>
  );
}
