'use client';

import { forwardRef, useState } from 'react';
import Input, { InputProps } from '@/components/common/Modal/components/ModalInput';
import EyeIcon from './ic-eye.svg';
import EyeLineThroughIcon from './ic-eye-line-through.svg';

export type PasswordInputProps = Omit<InputProps, 'type' | 'ref' | 'inputSuffix'>;

export default forwardRef<HTMLInputElement, PasswordInputProps>(function PasswordInput(props, ref) {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword((value) => !value);

  return (
    <Input
      ref={ref}
      type={showPassword ? 'text' : 'password'}
      className="placeholder:text-[#7BFAFC] text-white"
      errorClassName="text-center"
      inputSuffix={
        <button type="button" onClick={toggleShowPassword}>
          {showPassword ? (
            <EyeIcon className="w-[2rem]" />
          ) : (
            <EyeLineThroughIcon className="w-[2rem]" />
          )}
        </button>
      }
      {...props}
    />
  );
});
